import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { apiUrl, PORT } from '../environment/environment';
import Moment from 'react-moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Pagination from './Pagination/Pagination';

function Transactions() {
    let history = useHistory();
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let pageNumber = parseInt(query.get("p"));

    const [show, setShow] = useState(false);
    const [list, setList] = useState([]);
    const [searchname, setSearchName] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isSortedCol, setIsSortedCol] = useState();
    const [selectedPage, setSelectedPage] = useState(1);
    const [pageNum, setPageNum] = useState(pageNumber || 1);
    const [noOfRecords, setNoOfRecords] = useState(0);
    const limitValue = 10;
    const [modalData, setModalData] = useState('');
    const [columnArr, setColumnArr] = useState([
        { name: 'Key', value: 'key', dir: 1 },
        { name: 'Code', value: 'code', dir: 1 },
        { name: 'Value (Minute)', value: 'val', dir: 1 },
    ]);
    useEffect(() => {
        getList(pageNum);
    }, []);
    async function getList(val) {
        setSelectedPage(val);
        document.querySelector('.loader').classList.remove('d-none');
        var obj = {
            limitValue: limitValue,
            pageNumber: (val || pageNum),
            sortedCol: isSortedCol,
            sortedOrder: columnArr.filter(x => x.value === isSortedCol)[0]?.dir
        };
        await axios.post(`${apiUrl}${PORT}/admin/gettransaction`, obj)
            .then(function (response) {
                console.log("response", response);
                document.querySelector('.loader').classList.add('d-none');
                if (response.data.status == 1)
                    setList(response.data.result[0].paginatedResults);
                else
                    window.alert(response.data.message);
            }).catch(function (error) {
                document.querySelector('.loader').classList.add('d-none');
                window.alert(error);
            });
    };

    const isViewTransaction = (element) => {
        setModalData(element);
        setShow(true);
    }
    const isSortedColumn = (col, order) => {
        columnArr.forEach(ele => {
            if (ele.value === col) {
                ele.dir = ele.dir === 1 ? -1 : 1;
            }
        });
        setColumnArr(columnArr);
        setIsSortedCol(col);
        getList(selectedPage);
    }
    const handlsearchInputs = (e) => {
        setSearchName(e.target.value);
    }

    const clearClick = async e => {
        setSearchName('');
        getList();
    };

    const searchClick = async e => {
        setList([]);
        /* if (!searchname)
            getList();
        else {
            document.querySelector('.loading').classList.remove('d-none');
            await axios.post(`${apiUrl}${PORT}/trainer/trainer/searchtrainer`, { "searchname": searchname }, {}
            ).then(function (response) {
                document.querySelector('.loading').classList.add('d-none');
                if (response.data.status === 1) {
                    setList(response.data.result);
                } else {
                    window.alert(response.data.message);
                }
            }).catch(function (error) {
                window.alert(error);
            });
        } */
    };
    const curPage = (pageNum) => {
        history.push(`transactions?p=${pageNum}`);
        setPageNum(pageNum);
        getList(pageNum);
    }

    return (
        <>
            <div className="loader d-none">
                <div className="popuploader loginloader"></div>
            </div>
            <div className="container-fluid">
                <div className="title-box">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-12">
                            <h4 className="mb-0">Transactions</h4>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 text-right">
                            <div className="page-title float-right">
                                <ul className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/usermanagement">Home</Link></li>
                                    <li className="breadcrumb-item active">Transactions</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="box-card">
                        <div className="row">
                            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                                <div className="input-group">
                                    <input className="input-box" type="text" placeholder="Search..." onChange={(e) => handlsearchInputs(e)} name="searchinput" value={searchname} />
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn mt-0" onClick={searchClick}> <i className="fa fa-search pr-2"></i>Search</button>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn clear_btn mt-0" onClick={clearClick}><i className="fa fa-eraser pr-2"></i>Clear</button>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                            <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="search_btn mt-0"
                                    table="client_transactions"
                                    filename="tablexls"
                                    sheet="tablexls"
                                    buttonText="Export to excel" />
                                {/* <button className="search_btn mt-0"><i className="fa fa-file-excel-o mr-2"></i>Export Report</button> */}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12 record_table mt-2">
                                <div className="table-responsive">
                                    <table id='client_transactions' className="table table-bordered table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Client Name</th>
                                                <th>Plan Name</th>
                                                <th>Plan Date</th>
                                                <th>Amount</th>
                                                <th>Tax</th>
                                                <th>Payment Method</th>
                                                {/* <th className="text-center w130">Status</th> */}
                                                <th className="text-center w130">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.length > 0 ?
                                                list.map((ele, index) => {
                                                    
                                                    return (<tr>
                                                        <td>{index + 1}</td>
                                                        <td className="text-nowrap">{ele?.client_data?.firstname}</td>
                                                        <td>{ele.plantype}</td>
                                                        <td className="text-nowrap"><Moment format="DD MMMM YYYY, hh:mm A" date={ele.date} /></td>
                                                        <td>$ {ele.amount}</td>
                                                        <td>$ 35</td>
                                                        <td className="text-nowrap">Debit Card</td>
                                                        {/* <td>
                                                            <div className="onoffswitch">
                                                                <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch{{$index}}" />
                                                                <label className="onoffswitch-label" htmlFor="myonoffswitch{{$index}}">
                                                                    <span className="onoffswitch-inner active-deactive"></span>
                                                                    <span className="onoffswitch-switch"></span>
                                                                </label>
                                                            </div>
                                                        </td> */}
                                                        <td className="text-center">
                                                        <button className="btn btn-info mr-2" onClick={(e) => { isViewTransaction(ele) }}><i className="ace-icon fa fa-eye"></i></button>
                                                            {/* <button className="btn btn-danger"><i className="ace-icon fa fa-trash-o"></i></button> */}
                                                        </td>
                                                    </tr>
                                                    )
                                                })
                                                :
                                                <td className="text-center" colSpan="9">
                                                    No Record Found
                                                </td>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {list.length > 0 &&
                                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                                        <Pagination className="pagination-bar" currentPage={pageNum} totalCount={noOfRecords}
                                            pageSize={limitValue} onPageChange={page => curPage(page)} />
                                    </div>
                                }
                                {/* <paging ng-if="List.length > 0" pagenumber="PageNumber" pagesize="PageSize" totalrecords="TotalRecords" getlist="GetList"></paging> */}
                                {/* <paging pagenumber="PageNumber" pagesize="PageSize" totalrecords="TotalRecords" getlist="GetList" className="">
                                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                                        <div className="pagination">
                                            <a>«</a>
                                            <a className="active">1</a><a className="">2</a><a className="">3</a><a className="">4</a><a className="">5</a><a className="">6</a>
                                            <a>»</a>
                                        </div>
                                    </div>
                                </paging> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div id="loadermodal" className="loader d-none">
                    <div className="popuploader loginloader"></div>
                </div>
                <Modal.Header closeButton>
                    <Modal.Title>Transactions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12 pb-3 booking-history">
                        <div className="row">
                            <div className="col-lg-12 col-md-6 col-12">
                                <div className="row">
                                    <label className="col-md-5 col-12">Client Name <span>:</span></label>
                                    <div className="col-md-7 col-12">
                                        <span>{modalData?.client_data?.firstname}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-md-5 col-12">Plan Name  <span>:</span></label>
                                    <div className="col-md-7 col-12">
                                        <span>{modalData.plantype}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-md-5 col-12">Plan Date  <span>:</span></label>
                                    <div className="col-md-7 col-12">
                                        <span><Moment format="DD MMMM YYYY, hh:mm A" date={modalData.date} /></span>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-md-5 col-12">Amount  <span>:</span></label>
                                    <div className="col-md-7 col-12">
                                        <span>$ {modalData.amount}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-md-5 col-12">Tax <span>:</span></label>
                                    <div className="col-md-7 col-12">
                                        <span>$ 35</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-md-5 col-12">Payment Method <span>:</span></label>
                                    <div className="col-md-7 col-12">
                                        <span>Credit Card</span>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <label className="col-md-5 col-12">Status <span>:</span></label>
                                    <div className="col-md-7 col-12">
                                        <span>Active</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default Transactions;