import axios from 'axios';
import React, { useState } from 'react';
import { apiUrl, PORT } from '../environment/environment';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';

function ForgotPassword() {
    const history = useHistory();
    const usertype = localStorage.getItem('usertype');

    const [email, setUser] = useState("");

    const handleInputs = (e) => {
        setUser(e.target.value);
    }

    const PostForgotPassword = async (e) => {
        e.preventDefault();

        if (email === "") {
            swal({
                title: "Error!",
                text: "Please enter email.",
                icon: "error",
                button: true
            })
            //window.alert("Please enter email.");
            return false;
        }

        document.querySelector('.loader').classList.remove('d-none');
        await axios.post(`${apiUrl}${PORT}/${usertype}/account/passwordlink`, { "email": email }, {
        }).then(function (response) {
            document.querySelector('.loader').classList.add('d-none');
            if (response.data.status === 1) {
                //window.alert(response.data.message);
                swal({
                    title: "Sucess!",
                    text: response.data.message,
                    icon: "success",
                    button: true
                })
                window.location.href = "/login";
                //history.push("/login");
            }
            else {
                swal({
                    title: "Error!",
                    text: response.data.message,
                    icon: "error",
                    button: true
                })
                //window.alert(response.data.message);
            }
        }).catch(function (error) {
            document.querySelector('.loader').classList.add('d-none');
            console.log(error);
        });
    }

    return (
        <>
            <div className="bg-img">
                <div className="container">
                    <div className="col-md-12 col-sm-12 col-12">
                        <div className="row">
                            <div className="col-xl-5 col-md-8 col-12 mx-auto log-m">
                                <div id="loader" className="loader d-none">
                                    <div className="popuploader loginloader"></div>
                                </div>
                                <div className="reset_pass">
                                    <div className="modal-body">
                                        <h4 className="modal-title">Retrieve Password</h4>
                                        <div className="col-md-12 col-sm-12 col-12">
                                            <label>Enter your Username / Email</label>
                                            <div className="position-relative">
                                                <input onChange={(e) => handleInputs(e)} name="email" className="input-box" type="text" placeholder="Please enter Email" />
                                                <i className="fa fa-envelope log_i"></i>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-12 text-right">
                                            <button onClick={(e) => PostForgotPassword(e)} type="button" className="log_btn">Submit</button>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="col-md-12 col-sm-12 col-12 mr-2">
                                            <Link to="/login" className="back_btn">Back To Login<i className="fa fa-arrow-circle-left pl-2"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;
