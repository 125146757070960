import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { apiUrl, PORT } from '../../environment/environment';
import swal from 'sweetalert';
function TrainerDetails() {
    const [List, setList] = useState([]);
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let Id = query.get("Id");
    //Onload event set here.
    useEffect(() => {
        GetList();
    }, []);

    const GetList = () => {
        var obj = { availablestatus: -1, id: Id };
        document.querySelector('.loader').classList.remove('d-none');
        axios.post(`${apiUrl}${PORT}/trainer/trainer/trainerlist`, obj, {}).then(function (response) {
            document.querySelector('.loader').classList.add('d-none');
            if (response.data.status === 1) {
                setList(response.data?.result.trainerlist);
            } else {
                swal({
                    title: "Error!",
                    text: response.data.message,
                    icon: "error",
                    button: true
                })
                //window.alert(response.data.message);
            }
        }).catch(function (error) {
            document.querySelector('.loader').classList.add('d-none');
            window.alert(error);
        });
    };

    return (
        <>
            <div className="loader d-none">
                <div className="popuploader loginloader"></div>
            </div>
            <div className="container-fluid">
                <div className="title-box">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-12">
                            <h4 className="mb-0">Trainer Details</h4>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 text-right">
                            <div className="page-title float-right">
                                <ul className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/trainer">Trainers</Link></li>
                                    <li className="breadcrumb-item active">Trainer Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12 p-0">
                    {List.length > 0 &&
                        List.map((item, index) => {
                            return <div className="box-card" key={'index' + index}>
                                <div className="col-md-12 col-12 record_table mt-2 client_detail">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">First Name <span>:</span></label>
                                                <div className="col-md-7 col-12">
                                                    <span>{item.firstname}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">Last Name <span>:</span></label>
                                                <div className="col-md-7 col-12">
                                                    <span>{item.lastname}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">Email <span>:</span></label>
                                                <div className="col-md-7 col-12">
                                                    <span>{item.email}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">Password <span>:</span></label>
                                                <div className="col-md-7 col-12">
                                                    <span>{item.firstname}</span>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">Mobile No<span>:</span></label>
                                                <div className="col-md-7 col-12">
                                                    <span>{item.phoneno}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">Gender<span>:</span></label>
                                                <div className="col-md-7 col-12">
                                                    <span>{item.gender}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">Training Style<span>:</span></label>
                                                <div className="col-md-7 col-12">
                                                    <span>{item.trainingstyle} </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">Comment<span>:</span></label>
                                                <div className="col-md-7 col-12">
                                                    <span>{item.aboutus ? item.aboutus : 'N/A'} </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">Experience<span>:</span></label>
                                                <div className="col-md-7 col-12">
                                                    <span>{item.experience ? item.experience : 'N/A'}  year</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">Qualification Details<span>:</span></label>
                                                <div className="col-md-7 col-12">
                                                    <span>{item?.qualifications?.name ? item?.qualifications?.name : 'N/A'}  </span>
                                                    {item?.qualifications?.path?.length > 0 && item?.qualifications?.path.map((ele,qindex) => {
                                                        return (<div key={'qindex' + qindex} className="col-md-7 col-12 proimg pl-0">
                                                            <img src={apiUrl + PORT + ele.uri} title={ele.name} alt="" onError={(e) => { e.target.src = '/img/Small-no-img.png' }} />
                                                        </div>);
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">Fitness Speciality<span>:</span></label>
                                                <div className="col-md-7 col-12">
                                                    <span>{item?.Speciality ? item?.Speciality : 'N/A'}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">Introduction<span>:</span></label>
                                                <div className="col-md-7 col-12">
                                                    <span>{item?.introduction ? item?.introduction : 'N/A'}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">Certifications<span>:</span></label>
                                                <div className="col-md-7 col-12">
                                                    <span>Cross-fit Trainer</span>
                                                    {item?.certifications?.path?.length > 0 && item?.certifications?.path.map((ele,cindex) => {
                                                        return (<div key={'cindex' + cindex} className="col-md-7 col-12 proimg pl-0">
                                                            <img src={apiUrl + PORT + ele.uri} title={ele.name} onError={(e) => { e.target.src = '/img/Small-no-img.png' }} alt="" />
                                                        </div>);
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">Profile Image <span>:</span></label>
                                                <div className="col-md-7 col-12 proimg">
                                                    {item?.profile ?
                                                        <img src={apiUrl + PORT + item?.profile} alt="" onError={(e) => { e.target.src = '/img/Small-no-img.png' }} />
                                                        :
                                                        <img src={'/img/Small-no-img.png'} alt="" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="row">
                                                <label className="col-md-5 col-12">Cover Image<span>:</span></label>
                                                <div className="col-md-7 col-12 coverimg">
                                                    {item?.coverprofile ?
                                                        <img src={apiUrl + PORT + item?.coverprofile} alt="" onError={(e) => { e.target.src = '/img/No-Image.png' }} />
                                                        :
                                                        <img src={'/img/No-Image.png'} alt="" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                </div>
            </div>
        </>
    );
}

export default TrainerDetails;