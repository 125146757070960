import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { apiUrl, PORT } from '../environment/environment';
import Pagination from './Pagination/Pagination';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
function ManageGoalTypes() {
    let history = useHistory();
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let pageNumber = parseInt(query.get("p"));
    const [show, setShow] = useState(false);
    const [goal, setGoal] = useState({ id: '', name: "", status: true });
    const [list, setList] = useState([]);
    const [searchname, setSearchName] = useState('');
    const handleClose = () => setShow(false)
    const [errors, setErrors] = useState({});
    const [pageNum, setPageNum] = useState(pageNumber || 1);
    const [noOfRecords, setNoOfRecords] = useState(0);
    const [isSortedCol, setIsSortedCol] = useState();
    const [selectedPage, setSelectedPage] = useState(1);
    const limitValue = 10;
    const [columnArr, setColumnArr] = useState([
        { name: 'Goal Name', value: 'name', dir: 1 },
    ]);

    useEffect(() => {
        GetList(pageNum);
    }, []);
    async function GetList(val) {
        setSelectedPage(val);
        document.querySelector('.loader').classList.remove('d-none');
        var obj = {
            limitValue: limitValue,
            // pageNumber: (val * pageNum),
            pageNumber: (val || pageNum),
            sortedCol: isSortedCol,
            sortedOrder: columnArr.filter(x => x.value === isSortedCol)[0]?.dir
        };
        await axios.post(`${apiUrl}${PORT}/admin/getgoalstypes`, obj)
            .then(function (response) {
                document.querySelector('.loader').classList.add('d-none');
                if (response.data.status === 1) {
                    response.data.result?.goalslist?.forEach(element => {
                        element.status = (element.statusid === 1) ? true : false;
                    });
                    setNoOfRecords(response.data?.result?.noOfRecords || 0);
                    setList(response.data.result?.goalslist);
                   /*  swal({
                        title: "Success!",
                        text: response.data.message,
                        icon: "success",
                        button: true
                    }) */
                }
                else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    })
                    //window.alert(response.data.message);
                }
            }).catch(function (error) {
                document.querySelector('.loader').classList.add('d-none');
                window.alert(error);
            });
    };

    const isSortedColumn = (col, order) => {
        columnArr.forEach(ele => {
            if (ele.value === col) {
                ele.dir = ele.dir === 1 ? -1 : 1;
            }
        });
        setColumnArr(columnArr);
        setIsSortedCol(col);
        GetList(selectedPage);
    }

    const handlsearchInputs = (e) => {
        setSearchName(e.target.value);
    }

    const clearClick = async e => {
        setSearchName('');
        GetList(1);
    };

    const searchClick = async e => {
        if (!searchname)
            GetList(1);
        else {
            document.querySelector('.loader').classList.remove('d-none');
            await axios.post(`${apiUrl}${PORT}/admin/searchgoalstype`, { "searchname": searchname }, {}
            ).then(function (response) {
                document.querySelector('.loader').classList.add('d-none');
                if (response.data.status === 1) {
                    setList(response.data.result);
                } else {
                    window.alert(response.data.message);
                }
            }).catch(function (error) {
                window.alert(error);
            });
        }
    };

    const isEdit = (obj) => {
        setGoal({ ...goal, id: obj._id, name: obj.name, status: obj.status });
        setShow(true);
    }

    const isSave = (e) => {
        let isValid = true;
        var errormsg = {};

        if (goal.name === '') {
            errormsg.goal = "Please enter goal name!";
            isValid = false;
        }
        setErrors(errormsg);
        if (isValid) {
            document.querySelector('.loader').classList.remove('d-none');
            axios.post(`${apiUrl}${PORT}/admin/savegoalstypes`, goal)
                .then(function (response) {
                    document.querySelector('.loader').classList.add('d-none');
                    setShow(false);
                    if (response.data.status === 1) {
                        GetList(pageNum);
                        // GetList(1);
                        swal({
                            title: "Success!",
                            text: response.data.message,
                            icon: "success",
                            button: true
                        });
                    }
                    else {
                        swal({
                            title: "Error!",
                            text: response.data.message,
                            icon: "error",
                            button: true
                        })
                        //window.alert(response.data.message);
                    }
                }).catch(function (error) {
                    document.querySelector('.loader').classList.add('d-none');
                    window.alert(error);
                });
        }
    }

    const isDelete = (val) => {
        let obj = {
            id: val._id
        }
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: "Are you sure you Want to delete?",
            // text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete',
            reverseButtons: false,
            confirmButtonClass: "mx-2",
        }).then((result) => {
            if (result.isConfirmed) {
        document.querySelector('.loader').classList.remove('d-none');
        axios.post(`${apiUrl}${PORT}/admin/deletegoalstypes`, obj)
            .then(function (response) {
                document.querySelector('.loader').classList.add('d-none');
                setShow(false);
                if (response.data.status === 1) {
                    GetList(pageNum ?? 1);
                    swal({
                        title: "Success!",
                        text: response.data.message,
                        icon: "success",
                        button: true
                    })
                }
                else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    })
                    //window.alert(response.data.message);
                }
            }).catch(function (error) {
                document.querySelector('.loader').classList.add('d-none');
                window.alert(error);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
                
        }
    })
        // let obj = {
        //     id: val._id
        // }
        // var res = window.confirm("Are you sure you want to delete this record?")
        // if (res) {
        //     document.querySelector('.loader').classList.remove('d-none');
        //     axios.post(`${apiUrl}${PORT}/admin/deletegoalstypes`, obj)
        //         .then(function (response) {
        //             document.querySelector('.loader').classList.add('d-none');
        //             setShow(false);
        //             if (response.data.status === 1) {
        //                 GetList(pageNum ?? 1);
        //             }
        //             else {
        //                 swal({
        //                     title: "Error!",
        //                     text: response.data.message,
        //                     icon: "error",
        //                     button: true
        //                 })
        //                 //window.alert(response.data.message);
        //             }
        //         }).catch(function (error) {
        //             document.querySelector('.loader').classList.add('d-none');
        //             window.alert(error);
        //         });
        // }
    }

    const isShowModel = () => {
        setGoal({ ...goal, id: null, name: '', status: true});
        setErrors({});
        setShow(true);
    }

    const onChangeHandle = (e, ele) => {
        goal.id = ele._id;
        goal.name = ele.name;
        goal.status = e.currentTarget.checked ? 1 : 0;
        isSave(e);
    }
    const curPage = (pageNum) => {
        history.push(`managegoalstype?p=${pageNum}`);
        setPageNum(pageNum);
        GetList(pageNum);
    }

    return (
        <>
            <div className="loader d-none">
                <div className="popuploader loginloader"></div>
            </div>
            <div className="container-fluid">
                <div className="title-box">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-12">
                            <h4 className="mb-0">Manage Goals Type</h4>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 text-right">
                            <div className="page-title float-right">
                                <ul className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/usermanagement">Home</Link></li>
                                    <li className="breadcrumb-item active">Manage Goals Type</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="box-card">
                        <div className="row">
                            <div className="col-xl-4 col-md-6 col-sm-6 col-12">
                                <div className="input-group">
                                    <input className="input-box" type="text" placeholder="Search..." onChange={(e) => handlsearchInputs(e)} name="searchinput" value={searchname} />
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn mt-0" onClick={searchClick}> <i className="fa fa-search pr-2"></i>Search</button>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn clear_btn mt-0" onClick={clearClick}><i className="fa fa-eraser pr-2"></i>Clear</button>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn clear_btn mt-0" onClick={(e) => { isShowModel() }}><i className="fa fa-plus pr-2"></i>Add</button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12 record_table mt-2">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                {
                                                    columnArr.map((col, i) => {
                                                        return <th style={{ cursor: 'pointer' }} onClick={() => isSortedColumn(col.value, col.dir)}><div className="d-flex justify-content-between">{col.name}
                                                            <span className="align-self-center">
                                                                <i className="ml-2 fa fa-sort sort-gray"></i>
                                                                <i className={`${col.dir === 1 ? 'sort-i fa fa-sort-asc' : 'sort-i fa fa-sort-desc'}`}></i>
                                                            </span>
                                                        </div>
                                                        </th>
                                                    })
                                                }
                                                <th className="text-center">Status</th>
                                                <th className="text-center w130">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.length > 0 ?
                                                list.map((ele, index) => {
                                                    return (<tr>
                                                        <td>{(index + 1) + ((pageNum - 1) * limitValue)}</td>
                                                        <td>{ele.name}</td>
                                                        <td>
                                                            <div className="onoffswitch">
                                                                <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id={`myonoffswitch ${index}`} checked={ele.statusid === 1 ? true : false} onChange={(e) => { onChangeHandle(e, ele) }} />
                                                                <label className="onoffswitch-label" htmlFor={`myonoffswitch ${index}`}>
                                                                    <span className="onoffswitch-inner active-deactive"></span>
                                                                    <span className="onoffswitch-switch"></span>
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="d-flex justify-content-center">
                                                                <button className="btn btn-info mr-2" onClick={(e) => { isEdit(ele) }}><i className="ace-icon fa fa-pencil"></i></button>
                                                                <button className="btn btn-danger" onClick={(e) => { isDelete(ele) }}><i className="ace-icon fa fa-trash-o"></i></button>
                                                            </div>
                                                        </td>
                                                    </tr>)
                                                })
                                                :
                                                <td className="text-center" colSpan="10" ng-if="List.length == 0">
                                                    No Record Found
                                                </td>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {list.length > 0 &&
                                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                                        <Pagination className="pagination-bar" currentPage={pageNum} totalCount={noOfRecords}
                                            pageSize={limitValue} onPageChange={page => curPage(page)} />
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} scrollable={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div id="loadermodal" className="loader d-none">
                    <div className="popuploader loginloader"></div>
                </div>
                <Modal.Header closeButton>
                    <Modal.Title>Manage Goal Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12 pb-3">
                        <div className="row">
                            <div className="col-lg-12 col-md-6 col-12">
                                <label>Goal Name</label>
                                <div className="input-group">
                                    <input className="input-box m-0" type="text"
                                        value={goal.name} onChange={(e) => {
                                            setGoal({ ...goal, name: e.target.value })
                                        }}
                                    />
                                    <div className="text-danger">{errors.goal}</div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-6 col-12">
                                <label>Status</label>
                                <div className="onoffswitch m-0">
                                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitchedit"
                                        checked={goal.status} value={goal.status} onChange={(e) => { setGoal({ ...goal, status: e.currentTarget.checked }) }}
                                    />
                                    <label className="onoffswitch-label" htmlFor="myonoffswitchedit">
                                        <span className="onoffswitch-inner active-deactive"></span>
                                        <span className="onoffswitch-switch"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn cancel_btn" onClick={handleClose} >Cancel</Button>
                    <Button className="btn save_btn" variant="secondary" onClick={(e) => { isSave(e) }}>{goal.id ? 'Update' : 'Save'}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ManageGoalTypes;