import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { apiUrl, PORT } from '../environment/environment';
import Pagination from './Pagination/Pagination';

function ClientBookingHistory() {
    let history = useHistory();
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let pageNumber = parseInt(query.get("p"));
    const [list, setList] = useState([]);
    const [searchname, setSearchName] = useState('');
    const [pageNum, setPageNum] = useState(pageNumber || 1);
    const [noOfRecords, setNoOfRecords] = useState(0);
    const limitValue = 10;
    const [isSortedCol, setIsSortedCol] = useState();
    const [selectedPage, setSelectedPage] = useState(1);
    const [columnArr, setColumnArr] = useState([
        { name: 'First Name', value: 'client_data.firstname', dir: 1 },
        { name: 'Last Name', value: 'client_data.lastname', dir: 1 },
        { name: 'Email', value: 'client_data.email', dir: 1 },
        { name: 'Phone No', value: 'client_data.phoneno', dir: 1 },
        { name: 'Date', value: 'date', dir: 1 },
    ]);
    useEffect(() => {
        GetList(pageNum || 1);
    }, []);

    async function GetList(val) {
        setSelectedPage(val);
        document.querySelector('.loader').classList.remove('d-none');
        var obj = {
            limitValue: limitValue,
            pageNumber: (val || pageNum),
            sortedCol: isSortedCol,
            sortedOrder: columnArr.filter(x => x.value === isSortedCol)[0]?.dir
        };
        await axios.post(`${apiUrl}${PORT}/admin/clientBookinglist`, obj)
            .then(function (response) {
                document.querySelector('.loader').classList.add('d-none');
                if (response.data.status === 1) {
                    setNoOfRecords(response.data?.result[0]?.totalCount[0]?.count || 0);
                    setList(response.data?.result[0]?.paginatedResults);
                }
                else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    })
                    //window.alert(response.data.message);
                }
            }).catch(function (error) {
                document.querySelector('.loader').classList.add('d-none');
                window.alert(error);
            });
    };
    const isSortedColumn = (col, order) => {
        columnArr.forEach(ele => {
            if (ele.value === col) {
                ele.dir = ele.dir === 1 ? -1 : 1;
            }
        });
        setColumnArr(columnArr);
        setIsSortedCol(col);
        GetList(selectedPage);
    }
    const handlsearchInputs = (e) => {
        setSearchName(e.target.value);
    }

    const clearClick = async e => {
        setSearchName('');
        GetList(1);
    };

    const searchClick = async e => {
        /* if (!searchname)
            GetList(1);
        else {
            document.querySelector('.loading').classList.remove('d-none');
            await axios.post(`${apiUrl}${PORT}/trainer/trainer/searchtrainer`, { "searchname": searchname }, {}
            ).then(function (response) {
                document.querySelector('.loading').classList.add('d-none');
                if (response.data.status === 1) {
                    setList(response.data.result);
                } else {
                    window.alert(response.data.message);
                }
            }).catch(function (error) {
                window.alert(error);
            });
        } */
    };

    const curPage = (pageNum) => {
        history.push(`clientbookinghistory?p=${pageNum}`);
        setPageNum(pageNum);
        GetList(pageNum);
    }

    return (
        <>
            <div className="loader d-none">
                <div className="popuploader loginloader"></div>
            </div>

            <div className="container-fluid">
                <div className="title-box">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-12">
                            <h4 className="mb-0">Client Booking History</h4>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 text-right">
                            <div className="page-title float-right">
                                <ul className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/usermanagement">Home</Link></li>
                                    <li className="breadcrumb-item active">Client Booking History</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="box-card">
                        <div className="row">
                            <div className="col-xl-4 col-md-6 col-sm-6 col-12">
                                <div className="input-group">
                                    <input className="input-box" type="text" placeholder="Search..." onChange={(e) => handlsearchInputs(e)} name="searchinput" value={searchname} />
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn mt-0" onClick={searchClick}> <i className="fa fa-search pr-2"></i>Search</button>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn clear_btn mt-0" onClick={clearClick}><i className="fa fa-eraser pr-2"></i>Clear</button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12 record_table history mt-2">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                {
                                                    columnArr.map((col, i) => {
                                                        return <th style={{ cursor: 'pointer' }} onClick={() => isSortedColumn(col.value, col.dir)}><div className="d-flex justify-content-between">{col.name}
                                                                <span className="align-self-center">
                                                                    <i className="ml-2 fa fa-sort sort-gray"></i>
                                                                    <i className={`${col.dir === 1 ? 'sort-i fa fa-sort-asc' : 'sort-i fa fa-sort-desc'}`}></i>
                                                                </span>
                                                            </div>
                                                        </th>
                                                    })
                                                }
                                                {/* <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                <th>Phone No</th>
                                                <th>Date</th> */}
                                                <th>Time</th>
                                                <th>Connect Session</th>
                                                {/* <th className="text-center w130">Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.length > 0 ?
                                                list.map((ele, index) => {
                                                    return (
                                                        <tr key={'index' + index}>
                                                            <td>{(index + 1) + ((pageNum - 1) * limitValue)}</td>
                                                            <td>{ele?.client_data?.firstname}</td>
                                                            <td>{ele?.client_data?.lastname}</td>
                                                            <td>{ele?.client_data?.email}</td>
                                                            <td>{ele?.client_data?.phoneno}</td>
                                                            <td className="text-nowrap">{new Date(ele?.date).toDateString()}</td>
                                                            <td className="text-nowrap">{ele?.starthour} to {ele?.endhour}</td>
                                                            <td className="text-nowrap">{ele?.trainer_data?.trainingstyle} with {ele?.trainer_data?.firstname}</td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <td className="text-center" colSpan="10">
                                                    No Record Found
                                                </td>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {list.length > 0 &&
                                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                                        <Pagination className="pagination-bar" currentPage={pageNum} totalCount={noOfRecords}
                                            pageSize={limitValue} onPageChange={page => curPage(page)} />
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default ClientBookingHistory;