import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { apiUrl, PORT } from '../../environment/environment';

function ProfileManagment() {
    const [profile, setProfile] = useState({
        id: "", firstname: "", profile: '', lastname: "", email: "", fitnessGoals: '', phoneno: "", age: "", gender: "", height: '', weight: '', image: ''
    });
    const [errors, setErrors] = useState({});
    const defaultImg = '/img/favicon.png';
    const [imagepreview, setImagePreview] = useState(defaultImg);
    const [getImage, setGetImage] = useState('');

    useEffect(() => {
        getProfile();
    }, []);
    const getProfile = () => {
        axios.get(`${apiUrl}${PORT}/admin/getProfile`)
            .then((response) => {
                console.log("response", response);
                if (response.data.status == 1) {
                    setProfile(response.data.result);
                    console.log("apiUrl + PORT + response.data.result.profile", apiUrl + PORT + response.data.result.profile);
                    setImagePreview(apiUrl + PORT + response.data.result.profile);
                }
                console.log("success", response);
            }).catch((err) => {
                console.log("err", err);
            })
    }
    const submitProfile = () => {

        let isValid = true;
        var errormsg = {};

        let reg_email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (profile.firstname === "") {
            errormsg.firstname = "Please enter First Name.";
            isValid = false;
        }
        if (profile.lastname === "") {
            errormsg.lastname = "Please enter Last Name.";
            isValid = false;
        }
        if (profile.gender === "") {
            errormsg.gender = "Please Choose Gender.";
            isValid = false;
        }
        if (profile.fitnessGoals === "") {
            errormsg.fitnessGoals = "Please Choose FitnessGoals.";
            isValid = false;
        }
        if (profile.phoneno === "") {
            errormsg.phoneno = "Please Enter Phone Number.";
            isValid = false;
        }
        if (profile.height === "") {
            errormsg.height = "Please Enter Height.";
            isValid = false;
        }
        if (profile.weight === "") {
            errormsg.weight = "Please Enter Weight.";
            isValid = false;
        }
        if (profile.email === "") {
            errormsg.email = "Please enter Email.";
            isValid = false;
        } else if (reg_email.test(profile.email) === false) {
            errormsg.email = "Please enter valid Email.";
            isValid = false;
        }
        if (getImage !== '') {
            profile.profile = getImage.name;
            profile.image = getImage;
        }
        setErrors(errormsg);
        console.log("isValid", isValid);
        if (isValid === true) {

            /* const obj = {
                age: profile.age,
                phoneno: profile.phoneno,
                email: profile.email,
                firstname: profile.firstname,
                fitnessGoals: profile.fitnessGoals,
                gender: profile.gender,
                height: profile.height,
                id: profile.id,
                lastname: profile.lastname,
                image: profile.image,
                weight: profile.weight
            } */
            // console.log("obj", obj);
            /* var formData = new FormData();
            for (var key in obj) {
                formData.append(key, obj[key]);
            } */
            document.querySelector('.loader').classList.remove('d-none');
            axios.post(`${apiUrl}${PORT}/admin/updateadminusers`, profile).then(function (response) {
                document.querySelector('.loader').classList.add('d-none');
                if (response.data.status === 1) {
                    swal({
                        title: "Success!",
                        text: response.data.message,
                        icon: "success",
                        button: true
                    });
                } else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    });
                }
            }).catch(function (error) {
                window.alert(error);
            });
        }
    }
    const handleInputs = (e) => {
        setProfile({ ...profile, [e.target.name]: e.target.value });
    }

    const onFileChange = (event) => {
        const file_size = event.target.files[0].size;
        if (file_size > 2096000) {
            setImagePreview(defaultImg);
            alert("File size more than 2 MB. File size must under 2MB !");
            event.preventDefault();
        } else {
            const fileReader = new window.FileReader();
            const file = event.target.files[0];
            setGetImage(event.target.files[0]);

            fileReader.onload = fileLoad => {
                const { result } = fileLoad.target;
                setImagePreview(result);
            };

            fileReader.readAsDataURL(file);
        }
    };
    return (
        <>
            <div className="loader d-none">
                <div className="popuploader loginloader"></div>
            </div>
            <div className="container-fluid">
                <div className="title-box">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-12">
                            <h4 className="mb-0">Profile Managment</h4>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 text-right">
                            <div className="page-title float-right">
                                <ul className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Profile Managment</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="box-card">
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <div className="avatar-upload">
                                    <div className="avatar-edit">
                                        <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" onChange={onFileChange} />
                                        <label htmlFor="imageUpload"></label>
                                    </div>
                                    <div className="avatar-preview">
                                        <div id="imagePreview" style={{ backgroundImage: `url(${imagepreview})` }}></div>
                                    </div>
                                    <div className="prof_name">
                                        <h4>{profile.firstname}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <label>First Name</label>
                                        <div className="input-group">
                                            <input onChange={(e) => handleInputs(e)} name="firstname" className="input-box m-0" value={profile.firstname}
                                            /* onChange={(e) => {
                                                setProfile({ ...profile, firstname: e.target.value })
                                            }} */ type="text" />
                                        </div>
                                        <div className="text-danger">{errors.firstname}</div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <label>Last Name</label>
                                        <div className="input-group">
                                            <input onChange={(e) => handleInputs(e)} name="lastname" className="input-box m-0" value={profile.lastname}
                                            /* onChange={(e) => {
                                                setProfile({ ...profile, lastname: e.target.value })
                                            }} */ type="text" />
                                            <div className="text-danger">{errors.lastname}</div>
                                        </div>

                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <label>Gender</label>
                                        <div className="input-group">
                                            <i className="fa fa-angle-down darrow-i"></i>
                                            <select onChange={(e) => handleInputs(e)} name="gender" className="input-box" value={profile.gender}
                                            /* onChange={(e) => {
                                                setProfile({ ...profile, gender: e.target.value })
                                            }} */>
                                                <option value="">Select</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Non-Binary">Non-Binary</option>
                                            </select>
                                        </div>
                                        <div className="text-danger">{errors.gender}</div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <label>Fitness Goals</label>
                                        <div className="input-group">
                                            <i className="fa fa-angle-down darrow-i"></i>
                                            <select onChange={(e) => handleInputs(e)} name="fitnessGoals" className="input-box m-0" value={profile.fitnessGoals}
                                            /* onChange={(e) => {
                                                setProfile({ ...profile, fitnessGoals: e.target.value })
                                            }} */>
                                                <option>Select</option>
                                                <option value="01">Lower Body Fat</option>
                                                <option value="02">Lean And Toned</option>
                                                <option value="03">Build Muscle</option>
                                            </select>
                                        </div>
                                        <div className="text-danger">{errors.fitnessGoals}</div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <label>Email</label>
                                        <div className="input-group">
                                            <input onChange={(e) => handleInputs(e)} name="email" className="input-box m-0" value={profile.email} type="email"
                                            /* onChange={(e) => {
                                                setProfile({ ...profile, email: e.target.value })
                                            }} */ />
                                        </div>
                                        <div className="text-danger">{errors.email}</div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <label>Contact No</label>
                                        <div className="input-group">
                                            <input onChange={(e) => handleInputs(e)} name="phoneno" className="input-box m-0" value={profile.phoneno} type="text"
                                            /* onChange={(e) => {
                                                setProfile({ ...profile, phoneno: e.target.value })
                                            }} */ />
                                        </div>
                                        <div className="text-danger">{errors.phoneno}</div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <label>Height</label>
                                        <div className="input-group">
                                            <input onChange={(e) => handleInputs(e)} name="height" className="input-box m-0" value={profile.height} type="text"
                                            /* onChange={(e) => {
                                                setProfile({ ...profile, height: e.target.value })
                                            }} */ />
                                        </div>
                                        <div className="text-danger">{errors.height}</div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <label>Weight</label>
                                        <div className="input-group">
                                            <input onChange={(e) => handleInputs(e)} name="weight" className="input-box m-0" type="text" value={profile.weight}
                                            /* onChange={(e) => {
                                                setProfile({ ...profile, weight: e.target.value })
                                            }} */ />
                                        </div>
                                        <div className="text-danger">{errors.weight}</div>
                                    </div>

                                    <div className="col-md-12 col-12 text-right">
                                        <button className="search_btn w-25" type="button" onClick={() => submitProfile()}><i className="fa fa-user pr-2"></i>Update Profile</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default ProfileManagment;