import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { apiUrl, PORT } from '../environment/environment';
import Pagination from './Pagination/Pagination';
function Trainer() {

    let history = useHistory();
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let pageNumber = parseInt(query.get("p"));
    const [pageNum, setPageNum] = useState(pageNumber || 1);
    const [List, setList] = useState([]);
    const [searchname, setSearchName] = useState('');
    const [gender, setGender] = useState('');
    const [type, setType] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [userdetails, setUserDetails] = useState({
        _id: "", firstname: "", lastname: "", email: "", phoneno: "", gender: "", isactive: false, type: ""
    });
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [isLoader, setIsLoader] = useState(false);
    const [errors, setErrors] = useState({});

    const [noOfRecords, setNoOfRecords] = useState(0);
    const [isSortedCol, setIsSortedCol] = useState();
    const [selectedPage, setSelectedPage] = useState(1);
    const limitValue = 10;
    const [columnArr, setColumnArr] = useState([
        { name: 'First Name', value: 'firstname', dir: 1 },
        { name: 'Last Name', value: 'lastname', dir: 1 },
        { name: 'Email', value: 'email', dir: 1 },
        { name: 'Phone No', value: 'phoneno', dir: 1 },
        { name: 'Gender', value: 'gender', dir: 1 },
    ]);
    //Onload event set here.
    useEffect(() => {
        GetList(pageNumber || 1);
    }, []);
    const handlsearchInputs = (e) => {
        setSearchName(e.target.value);
    }
    const handleChange = (e) => {
        setGender(e);
        setUserDetails({ ...userdetails, gender: e });
    }
    const handleTypeChange = (e) => {
        setType(e);
        setUserDetails({ ...userdetails, type: e });
    }
    const handleInputs = (e) => {
        setUserDetails({ ...userdetails, [e.target.name]: e.target.value });
    }
    const editClick = async e => {
        //setShow(true);
        handleShow();
        setIsActive(e.statusid);
        setGender(e.gender);
        setType(e.type);
        setUserDetails({ ...userdetails, _id: e._id, firstname: e.firstname, lastname: e.lastname, email: e.email, phoneno: e.phoneno, gender: e.gender, isactive: e.statusid, type: e.type });
        //SetEditList(e);
    };
    const handleOnChange = () => {
        setIsActive(!isActive);
        setUserDetails({ ...userdetails, isactive: !isActive });
    };
    const saveClick = async e => {
        e.preventDefault();
        let isValid = true;
        var errormsg = {};
        let reg_email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (userdetails.firstname === "") {
            errormsg.firstname = "Please enter First Name.";
            isValid = false;
            // window.alert("Please enter first name.");
            // swal({
            //     title: "Error!",
            //     text: "Please enter first name.",
            //     icon: "error",
            //     button: true

            // })
            // return false;

        }
        if (userdetails.lastname === "") {
            errormsg.lastname = "Please enter Last Name.";
            isValid = false;
            //window.alert("Please enter last name.");
            // swal({
            //     title: "Error!",
            //     text: "Please enter last name.",
            //     icon: "error",
            //     button: true
            // })
            // return false;
        }
        if (userdetails.email === "") {
            errormsg.email = "Please enter Email.";
            isValid = false;
            //window.alert("Please enter email.");
            // swal({
            //     title: "Error!",
            //     text: "Please enter email.",
            //     icon: "error",
            //     button: true
            // })
            // return false;
        } else if (reg_email.test(userdetails.email) === false) {
            errormsg.email = "Please enter valid Email.";
            isValid = false;
        }
        if (userdetails.phoneno === "") {
            errormsg.phoneno = "Please enter Phone number.";
            isValid = false;
            //window.alert("Please enter phoneno.");
            // swal({
            //     title: "Error!",
            //     text: "Please enter phoneno.",
            //     icon: "error",
            //     button: true
            // })
            // return false;
        }
        setErrors(errormsg);
        if (isValid) {
            setIsLoader(true);
            await axios.post(`${apiUrl}${PORT}/trainer/trainer/savetrainer`, userdetails, {
            }).then(function (response) {
                setIsLoader(false);
                // document.querySelector('.loader').classList.add('d-none');
                if (response.data.status === 1) {
                    //dispose the modal popup
                    //setShow(false);
                    handleClose();
                    searchClick();

                    // swal({
                    //     title: "Success!",
                    //     text: response.data.message,
                    //     icon: "success",
                    //     button: true
                    // })
                    //alert(response.data.message);
                    swal({
                        title: "Success!",
                        text: response.data.message,
                        icon: "success",
                        button: true
                    });
                }
                else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    })
                    //window.alert(response.data.message);
                }
            }).catch(function (error) {
                setIsLoader(false);
                window.alert(error);
            });
        }
    };

    const deleteClick = async e => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: "Are you sure you Want to delete?",
            // text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete',
            reverseButtons: false,
            confirmButtonClass: "mx-2",
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoader(true);
                axios.post(`${apiUrl}${PORT}/trainer/trainer/deletetrainer`, { "_id": e }, {}
                ).then(function (response) {
                    setIsLoader(false);
                    if (response.data.status === 1) {
                        GetList(pageNum ?? 1);
                        swal({
                            title: "Success!",
                            text: response.data.message,
                            icon: "success",
                            button: true
                        })
                    }
                    else {
                        swal({
                            title: "Error!",
                            text: response.data.message,
                            icon: "error",
                            button: true
                        })
                    }
                }).catch(function (error) {
                    setIsLoader(false);
                    window.alert(error);
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        })

    };

    const GetList = (val) => {
        setSelectedPage(val);
        setIsLoader(true);
        var obj = {
            availablestatus: 0,
            limitValue: limitValue,
            pageNumber: (val || pageNum),
            sortedCol: isSortedCol,
            sortedOrder: columnArr.filter(x => x.value === isSortedCol)[0]?.dir
        };
        axios.post(`${apiUrl}${PORT}/admin/trainerlist`, obj).then(function (response) {
            setIsLoader(false);
            if (response.data.status === 1) {
                setNoOfRecords(response.data?.result?.noOfRecords || 0);
                setList(response.data.result?.trainerlist);
            } else {
                swal({
                    title: "Error!",
                    text: response.data.message,
                    icon: "error",
                    button: true
                });
            }
        }).catch(function (error) {
            setIsLoader(false);
            window.alert(error);
        });
    };

    const isSortedColumn = (col, order) => {
        columnArr.forEach(ele => {
            if (ele.value === col) {
                ele.dir = ele.dir === 1 ? -1 : 1;
            }
        });
        setColumnArr(columnArr);
        setIsSortedCol(col);
        GetList(selectedPage);
    }

    const clearClick = async e => {
        setSearchName('');
        GetList(1);
    };
    const searchClick = async e => {
        if (!searchname)
            GetList(1);
        else {
            setIsLoader(true);
            await axios.post(`${apiUrl}${PORT}/trainer/trainer/searchtrainer`, { "searchname": searchname }, {}
            ).then(function (response) {
                setIsLoader(false);
                if (response.data.status === 1) {
                    setList(response.data.result);
                } else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    })
                    //window.alert(response.data.message);
                }
            }).catch(function (error) {
                setIsLoader(false);
                window.alert(error);
            });
        }
    };

    const curPage = (pageNum) => {
        history.push(`/trainer?p=${pageNum}`);
        setPageNum(pageNum);
        GetList(pageNum);
    }
    const onChangeHandle = (e, obj) => {
        userdetails._id = obj._id;
        userdetails.firstname = obj.firstname;
        userdetails.lastname = obj.lastname;
        userdetails.email = obj.email;
        userdetails.phoneno = obj.phoneno;
        userdetails.gender = obj.gender;
        userdetails.type = obj.type;
        userdetails.isactive = e.currentTarget.checked ? true : false;
        saveClick(e);
    }

    return (
        <>
            {isLoader &&
                <div className="loader">
                    <div className="popuploader loginloader"></div>
                </div>
            }
            <div className="container-fluid">
                <div className="title-box">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-12">
                            <h4 className="mb-0">Trainer Management</h4>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 text-right">
                            <div className="page-title float-right">
                                <ul className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/usermanagement">Home</Link></li>
                                    <li className="breadcrumb-item active">Trainer</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="box-card">
                        <div className="row">
                            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                                <div className="input-group">
                                    <input className="input-box" type="text" placeholder="Search..." onChange={(e) => handlsearchInputs(e)} name="searchinput" value={searchname} />
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn mt-0" onClick={searchClick} type="button"> <i className="fa fa-search pr-2"></i>Search</button>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn clear_btn mt-0" onClick={clearClick}><i className="fa fa-eraser pr-2"></i>Clear</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-12 record_table mt-2">
                                <div className="table-responsive client-trainer">
                                    <table className="table table-bordered table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                {
                                                    columnArr.map((col, i) => {
                                                        return <th key={'i' + i} style={{ cursor: 'pointer' }} onClick={() => isSortedColumn(col.value, col.dir)}><div className="d-flex justify-content-between">{col.name}
                                                            <span className="align-self-center">
                                                                <i className="ml-2 fa fa-sort sort-gray"></i>
                                                                <i className={`${col.dir === 1 ? 'sort-i fa fa-sort-asc' : 'sort-i fa fa-sort-desc'}`}></i>
                                                            </span>
                                                        </div>
                                                        </th>
                                                    })
                                                }
                                                <th className="text-center w130">Star</th>
                                                <th className="text-center w130">Status</th>
                                                <th className="text-center w130">Type</th>
                                                <th className="text-center w240">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {List.length > 0 ?
                                                List.map((listItems, index) => {
                                                    return <tr key={'key' + index}>
                                                        <td>{(index + 1) + ((pageNum - 1) * limitValue)}</td>
                                                        <td>{listItems.firstname}</td>
                                                        <td>{listItems.lastname}</td>
                                                        <td>{listItems.email}</td>
                                                        <td>{listItems.phoneno}</td>
                                                        <td className="text-nowrap">{listItems.gender}</td>
                                                        <td>
                                                            <Rating initialValue="3.5" size="17" readonly="true" allowHover="false" allowHalfIcon="true" />
                                                        </td>
                                                        <td>
                                                            {/* <div className="onoffswitch">
                                                                <input type="checkbox" checked={listItems.statusid === 1 ? true : false} value={listItems.statusid} name="onoffswitch" className="onoffswitch-checkbox" id={'myonoffswitch' + index} />
                                                                <label className="onoffswitch-label" htmlFor={'myonoffswitch' + index}>
                                                                    <span className="onoffswitch-inner active-deactive"></span>
                                                                    <span className="onoffswitch-switch"></span>
                                                                </label>
                                                            </div> */}
                                                            <div className="onoffswitch">
                                                                <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id={`myonoffswitch ${index}`} checked={listItems.statusid === 1 ? true : false} onChange={(e) => { onChangeHandle(e, listItems) }} />
                                                                <label className="onoffswitch-label" htmlFor={`myonoffswitch ${index}`}>
                                                                    <span className="onoffswitch-inner active-deactive"></span>
                                                                    <span className="onoffswitch-switch"></span>
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>{listItems.type || '-'}</td>
                                                        <td className="text-center">
                                                            <div className="d-flex justify-content-center">
                                                                <button className="btn btn-info mr-2" onClick={() => editClick(listItems)}><i className="ace-icon fa fa-pencil"></i></button>
                                                                <button className="btn btn-danger mr-2" onClick={() => deleteClick(listItems._id)}><i className="ace-icon fa fa-trash-o"></i></button>
                                                                <Link className="btn btn-eye" to={'/trainerdetails?Id=' + listItems._id}><i className="ace-icon fa fa-eye"></i></Link>
                                                                <button className="btn btn-comment d-none" onClick={handleShow1}><i className="ace-icon fa fa-comment"></i></button>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                }) :
                                                <td className="text-center no-record" colSpan="10">
                                                    No record found!
                                                </td>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {List.length > 0 &&
                                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                                        <Pagination className="pagination-bar" currentPage={pageNum} totalCount={noOfRecords}
                                            pageSize={limitValue} onPageChange={page => curPage(page)} />
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} scrollable={true} aria-labelledby="contained-modal-title-vcenter" centered>
                <div id="loadermodal" className="loader d-none">
                    <div className="popuploader loginloader"></div>
                </div>
                <Modal.Header closeButton>
                    <Modal.Title>Update Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="Details">
                        <div className="col-md-12 col-12 py-3">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <label>First Name</label>
                                    <div className="input-group">
                                        <input value={userdetails.firstname} onChange={(e) => handleInputs(e)} name="firstname" className="input-box m-0" type="text" />
                                    </div>
                                    <div className="text-danger">{errors.firstname}</div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <label>Last Name</label>
                                    <div className="input-group">
                                        <input value={userdetails.lastname} onChange={(e) => handleInputs(e)} name="lastname" className="input-box m-0" type="text" />
                                    </div>
                                    <div className="text-danger">{errors.lastname}</div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <label>Email</label>
                                    <div className="input-group">
                                        <input value={userdetails.email} onChange={(e) => handleInputs(e)} name="email" className="input-box m-0" type="email" />
                                    </div>
                                    <div className="text-danger">{errors.email}</div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <label>Phone No</label>
                                    <div className="input-group">
                                        <input value={userdetails.phoneno} onChange={(e) => handleInputs(e)} name="phoneno" className="input-box m-0" type="text" />
                                    </div>
                                    <div className="text-danger">{errors.phoneno}</div>
                                </div>
                                <div className="col-md-6 col-sm-4 col-12">
                                    <label>Gender</label>
                                    <div className="input-group">
                                        <i className="fa fa-angle-down darrow-i"></i>
                                        <select className="input-box" value={gender} onChange={(event) => handleChange(event.target.value)}>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Non-Binary">Non-Binary</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-4 col-12">
                                    <label>Type</label>
                                    <div className="input-group">
                                        <i className="fa fa-angle-down darrow-i"></i>
                                        <select className="input-box" value={type} onChange={(event) => handleTypeChange(event.target.value)}>
                                            <option>Select type</option>
                                            <option value="Standard">Standard</option>
                                            <option value="ELite">ELite</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <label>Status</label>
                                    <div className="onoffswitch m-0">
                                        <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitchedit" onChange={handleOnChange} checked={isActive} />
                                        <label className="onoffswitch-label" htmlFor="myonoffswitchedit">
                                            <span className="onoffswitch-inner active-deactive"></span>
                                            <span className="onoffswitch-switch"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary btn save_btn" onClick={saveClick}>{userdetails._id ? 'Update' : 'Save'} </Button>
                </Modal.Footer>
            </Modal>
            {/* Trainer chat history modal popup */}
            <Modal show={show1} onHide={handleClose1} scrollable={true} aria-labelledby="contained-modal-title-vcenter" centered>
                <div id="loadermodal" className="loader d-none">
                    <div className="popuploader loginloader"></div>
                </div>
                <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="message">
                        <div className="col-md-12 col-12 py-3">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-12">
                                    <div className="d-flex chat-msg">
                                        <div className="profile-img">
                                            <img src="/img/user-1.jpg" alt='' />
                                        </div>
                                        <div className="chat-content">
                                            <h4>Zoey</h4>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex chat-msg">
                                        <div className="profile-img">
                                            <img src="/img/myprofile.png" alt='' />
                                        </div>
                                        <div className="chat-content">
                                            <h4>Ross</h4>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex chat-msg">
                                        <div className="profile-img">
                                            <img src="/img/user-1.jpg" alt='' />
                                        </div>
                                        <div className="chat-content">
                                            <h4>Mike</h4>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-12 text-center d-none">
                                    <h4 className="msg-text">No Message Found</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Trainer chat history modal popup End*/}
        </>
    );
}

export default Trainer;







