import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { Link, useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { apiUrl, PORT } from '../../environment/environment';
import Pagination from '../Pagination/Pagination';
function Notifications() {
    let history = useHistory();
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let pageNumber = parseInt(query.get("p"));

    const [show, setShow] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [searchname, setSearchName] = useState('');
    const [pageNum, setPageNum] = useState(pageNumber || 1);
    const [noOfRecords, setNoOfRecords] = useState(0);
    const [List, setList] = useState([]);
    const handleClose = () => setShow(false);
    const [isSortedCol, setIsSortedCol] = useState();
    const [selectedPage, setSelectedPage] = useState(1);
    const limitValue = 10;
    const [columnArr, setColumnArr] = useState([
        { name: 'Date', value: 'date', dir: 1 },
        { name: 'Title', value: 'title', dir: 1 },
        { name: 'Receiver', value: 'firstname', dir: 1 },
    ]);
    useEffect(() => {
        GetList(pageNum);
    }, []);


    const GetList = (val) => {
        setSelectedPage(val);
        setIsLoader(true);
        var obj = {
            limitValue: limitValue,
            pageNumber: (val || pageNum),
            sortedCol: isSortedCol,
            sortedOrder: columnArr.filter(x => x.value === isSortedCol)[0]?.dir
        };
        axios.post(`${apiUrl}${PORT}/admin/notificationlist`, obj).then(function (response) {
            setIsLoader(false);
            if (response.data.status === 1) {
                setNoOfRecords(response.data?.result?.count || 0);
                setList(response.data?.result?.list);
            } else {
                swal({
                    title: "Error!",
                    text: response.data.message,
                    icon: "error",
                    button: true
                })
            }
        }).catch(function (error) {
            setIsLoader(false);
            window.alert(error);
        });
    };

    const isSortedColumn = (col, order) => {
        columnArr.forEach(ele => {
            if (ele.value === col) {
                ele.dir = ele.dir === 1 ? -1 : 1;
            }
        });
        setColumnArr(columnArr);
        setIsSortedCol(col);
        GetList(selectedPage);
    }

    const curPage = (pageNum) => {
        history.push(`notifications?p=${pageNum}`)
        setPageNum(pageNum);
        GetList(pageNum);
    }

    const handlsearchInputs = (e) => {
        setSearchName(e.target.value);
    }

    const searchClick = async e => {
        if (!searchname)
            GetList(1);
        else {
            setIsLoader(true);
            await axios.post(`${apiUrl}${PORT}/trainer/trainer/searchtrainer`, { "searchname": searchname }, {}
            ).then(function (response) {
                setIsLoader(false);
                if (response.data.status === 1) {
                    setList(response.data.result);
                } else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    })
                }
            }).catch(function (error) {
                setIsLoader(false);
                window.alert(error);
            });
        }
    };

    const clearClick = async e => {
        setSearchName('');
        GetList(1);
    };

    return (
        <>
            {isLoader &&
                <div className="loader">
                    <div className="popuploader loginloader"></div>
                </div>
            }
            <div className="container-fluid">
                <div className="title-box">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-12">
                            <h4 className="mb-0">Notification</h4>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 text-right">
                            <div className="page-title float-right">
                                <ul className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/usermanagement">Home</Link></li>
                                    <li className="breadcrumb-item active">Notification</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="box-card">
                        <div className="row">
                            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                                <div className="input-group">
                                    <input className="input-box" type="text" placeholder="Search..." onChange={(e) => handlsearchInputs(e)} name="searchinput" value={searchname} />
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn mt-0" onClick={searchClick} type="button"> <i className="fa fa-search pr-2"></i>Search</button>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn clear_btn mt-0" onClick={clearClick}><i className="fa fa-eraser pr-2"></i>Clear</button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12 record_table mt-2">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                {
                                                    columnArr.map((col, i) => {
                                                        return <th style={{ cursor: 'pointer' }} onClick={() => isSortedColumn(col.value, col.dir)}><div className="d-flex justify-content-between">{col.name}
                                                            <span className="align-self-center">
                                                                <i className="ml-2 fa fa-sort sort-gray"></i>
                                                                <i className={`${col.dir === 1 ? 'sort-i fa fa-sort-asc' : 'sort-i fa fa-sort-desc'}`}></i>
                                                            </span>
                                                        </div>
                                                        </th>
                                                    })
                                                }
                                                <th className="wd275">Description</th>
                                                {/* <th className="text-center w130">Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {List.length > 0 ?
                                                List.map((ele, index) => {
                                                    return (<tr key={'index' + index}>
                                                        <td>{(index + 1) + ((pageNum - 1) * limitValue)}</td>
                                                        <td>{new Date(ele?.date).toDateString()}</td>
                                                        <td>{ele?.title}</td>
                                                        <td>{ele?.user_data?.firstname}</td>
                                                        <td><span className="noti-disc">{ele?.message}</span></td>
                                                        {/* <td className="text-center">
                                                            <button className="btn btn-danger"><i className="ace-icon fa fa-trash-o"></i></button>
                                                        </td> */}
                                                    </tr>
                                                    )
                                                })
                                                :
                                                <td className="text-center no-record" colSpan="10">
                                                    No record found!
                                                </td>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {List.length > 0 &&
                                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                                        <Pagination className="pagination-bar" currentPage={pageNum} totalCount={noOfRecords}
                                            pageSize={limitValue} onPageChange={page => curPage(page)} />
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div id="loadermodal" className="loader d-none">
                    <div className="popuploader loginloader"></div>
                </div>
                <Modal.Header closeButton>
                    <Modal.Title>Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12 pb-3 booking-history">
                        <div className="row">
                            <div className="col-lg-12 col-md-6 col-12">
                                <div className="row">
                                    <label className="col-md-5 col-12">Date<span>:</span></label>
                                    <div className="col-md-7 col-12">
                                        <span>02-01-2022</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-md-5 col-12">Title <span>:</span></label>
                                    <div className="col-md-7 col-12">
                                        <span>Session Confirmed</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-md-5 col-12">Description <span>:</span></label>
                                    <div className="col-md-7 col-12">
                                        <span>Your session with Ross</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-md-5 col-12">Sent by <span>:</span></label>
                                    <div className="col-md-7 col-12">
                                        <span>Ross</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-md-5 col-12">Sent To<span>:</span></label>
                                    <div className="col-md-7 col-12">
                                        <span>Ally Ashton</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default Notifications;